import { computed, defineComponent, reactive, ref, watch } from "@vue/composition-api";
import { User } from "@/models/User";
import SearchUser from "@/components/common/SearchUser.vue";
import { useEmitter } from "@/composable/emit";
const isEventResponsibilitiesFulFilled = (rs) => {
    return rs.every(r => r.user !== null);
};
export default defineComponent({
    name: "EventResponsibilities",
    components: {
        SearchUser,
    },
    props: {
        responsibilities: {
            type: Array,
            required: true,
        },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    emits: {
        update: (responsibilities) => responsibilities !== undefined,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const sortResponsibilities = () => {
            eventResponsibilities.value.sort((r1, r2) => r1.time.valueOf() - r2.time.valueOf());
        };
        const editingResponsibilityId = ref(-1);
        const eventResponsibilities = ref(props.responsibilities.map(responsibility => ({ ...responsibility, id: Math.random() })));
        sortResponsibilities();
        const empty = computed(() => props.required && eventResponsibilities.value.length === 0);
        const responsibilitiesFulFilled = computed(() => isEventResponsibilitiesFulFilled(eventResponsibilities.value));
        const setUser = (index, newUser) => {
            eventResponsibilities.value[index].user = new User(newUser);
            editingResponsibilityId.value = -1;
            emitResponsibilities();
        };
        const setTime = (index, time) => {
            eventResponsibilities.value[index].time = time;
            sortResponsibilities();
            emitResponsibilities();
        };
        const removeResponsibility = (index) => {
            eventResponsibilities.value.splice(index, 1);
            emitResponsibilities();
        };
        const emitResponsibilities = () => {
            if (!isEventResponsibilitiesFulFilled(eventResponsibilities.value))
                return;
            emit("change-event-responsibilities", eventResponsibilities.value);
        };
        const editingNewResponsibilityUser = ref(true);
        const newResponsibility = reactive({
            id: Math.random(),
            time: new Date(),
            user: null,
        });
        const newResponsibilityFulfilled = computed(() => newResponsibility.user !== null);
        const setNewResponsibilityUser = (newUser) => {
            newResponsibility.user = newUser === null ? null : new User(newUser);
        };
        const setNewResponsibilityTime = (time) => {
            newResponsibility.time = time;
        };
        const addNewResponsibility = () => {
            if (newResponsibility.user === null)
                return;
            const { time, user, id } = newResponsibility;
            eventResponsibilities.value.push({ time, user, id });
            sortResponsibilities();
            emitResponsibilities();
            newResponsibility.id = Math.random();
            newResponsibility.time = new Date();
            newResponsibility.user = null;
        };
        watch(() => props.readonly, () => { editingResponsibilityId.value = -1; });
        return {
            eventResponsibilities,
            responsibilitiesFulFilled,
            editingResponsibilityId,
            editingNewResponsibilityUser,
            empty,
            removeResponsibility,
            setUser,
            setTime,
            newResponsibility,
            newResponsibilityFulfilled,
            setNewResponsibilityTime,
            setNewResponsibilityUser,
            addNewResponsibility,
        };
    },
});
