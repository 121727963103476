import { computed, defineComponent, ref } from "@vue/composition-api";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldActivationTime",
    props: {
        time: { type: null, required: true },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    emits: {
        update: (time) => time instanceof Date,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const timeValue = ref(props.time);
        const empty = computed(() => props.required && props.time === null);
        const setTime = (date = new Date()) => {
            timeValue.value = date;
            emitDate();
        };
        const emitDate = () => {
            if (timeValue.value === null)
                return;
            emit("update", timeValue.value);
        };
        return {
            timeValue,
            empty,
            setTime,
            emitDate,
        };
    },
});
