import { computed, ref } from "@vue/composition-api";
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useCreateUserFields({ showFields, clearErrors }) {
    const allFields = ref([
        {
            name: "lastName",
            required: true,
            value: "",
            placeholder: "Прізвище",
        },
        {
            name: "firstName",
            required: true,
            value: "",
            placeholder: "Імя",
        },
        {
            name: "middleName",
            required: true,
            value: "",
            placeholder: "По-Батькові",
        },
        {
            name: "address",
            required: true,
            value: "",
            placeholder: "Місце проживання",
        },
        {
            name: "phone",
            required: true,
            value: "",
            placeholder: "Номер телефону, в виді: +380xxxxxxxxx",
        },
        {
            name: "additionalInfo",
            required: false,
            value: "",
            placeholder: "Додаткова інформація",
        },
    ]);
    const fields = computed(() => allFields.value.filter(f => showFields.value.includes(f.name)));
    const fieldsFulfilled = computed(() => fields.value.filter(f => f.required).every(f => f.value !== ""));
    const getField = (name) => {
        const field = allFields.value.find(f => f.name === name);
        if (field === undefined)
            throw new Error(`NOT FOUND FIELD: ${name}`);
        return field;
    };
    const updateField = (name, value) => {
        const field = getField(name);
        field.value = value;
        clearErrors([name]);
    };
    const clearFields = () => {
        fields.value.forEach(field => {
            field.value = "";
        });
        clearErrors();
    };
    const fieldsToData = computed(() => {
        const userDataFields = ["lastName", "firstName", "middleName", "address", "phone", "additionalInfo"];
        const [lastName, firstName, middleName, address, phone, info] = userDataFields.map(f => getField(f).value);
        return {
            lastName,
            firstName,
            middleName,
            address,
            phone,
            additionalInfo: info === "" ? null : info,
        };
    });
    return {
        fields,
        fieldsFulfilled,
        fieldsToData,
        updateField,
        clearFields,
    };
}
