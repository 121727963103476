import { defineComponent } from "@vue/composition-api";
export default defineComponent({
    name: "EventFieldCreationTime",
    props: {
        time: { type: Date, required: true },
    },
    emits: {
        update: (time) => time instanceof Date,
    },
});
