<template>
  <div class="organization-structure">
    <AppLoader v-if="!dataDownloaded" icon-left icon-scale="1.2">
      Завантаження даних
    </AppLoader>

    <AppSelectableList
      v-else
      v-slot="{item: region, selected: selectedRegion}"
      :list="regions"
      key-field="id"
      :selected-item-values="regionIds"
    >
      <div class="organization-structure--item region">
        <span
          v-if="selectable"
          :class="{active: selectedRegion}"
          tabindex="0"
          @click="toggleRegion(region.id)"
          @keypress.enter="toggleRegion(region.id)"
        >
          {{ region.title }}
        </span>

        <AppSelectableList
          v-slot="{item: group, selected: selectedGroup}"
          :list="groups.filter(g => g.region.id === region.id && regionIds.includes(g.region.id))"
          key-field="id"
          :selected-item-values="groupIds"
        >
          <div class="organization-structure--item group">
            <span
              v-if="selectable"
              :class="{active: selectedGroup}"
              tabindex="0"
              @click="toggleGroup(group.id)"
              @keypress.enter="toggleGroup(group.id)"
            >
              - {{ group.title }}
            </span>

            <AppSelectableList
              v-slot="{item: department, selected: selectedDepartment}"
              :list="departments.filter(d=> (d.group.id === group.id && groupIds.includes(d.group.id)))"
              :selected-item-values="departmentIds"
              key-field="id"
            >
              <div class="organization-structure--item department">
                <span
                  v-if="selectable"
                  :class="{active: selectedDepartment}"
                  tabindex="0"
                  @click="toggleDepartment(department.id)"
                  @keypress.enter="toggleDepartment(department.id)"
                >
                  --- {{ department.title }}
                  <AppLoader v-if="downloading" class="spinner" icon-scale="1.3" />
                </span>

                <slot
                  :region="region"
                  :group="group"
                  :department="department"
                  :regionIds="regionIds"
                  :groupIds="groupIds"
                  :departmentIds="departmentIds"
                  :isRegionSelected="selectedRegion"
                  :isGroupSelected="selectedGroup"
                  :isDepartmentSelected="selectedDepartment"
                />
              </div>
            </AppSelectableList>

          </div>
        </AppSelectableList>

      </div>
    </AppSelectableList>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { fetchRegions, fetchGroups2 as fetchGroups, fetchDepartments } from "../../api/office";

let dataDownloaded = false;
let dataDownloading = false;
let regions = [];
let groups = [];
let departments = [];

const getData = async () => {
  if (dataDownloading) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getData());
      }, 1000);
    });
  }

  if (dataDownloaded) {
    return [regions, groups, departments];
  }

  dataDownloading = true;

  [regions, groups, departments] = await Promise.all([
    fetchRegions({ limit: 100 }),
    fetchGroups([], { limit: 100 }),
    fetchDepartments([], { limit: 100 }),
  ]);

  dataDownloaded = true;
  dataDownloading = false;
  return [regions, groups, departments];
};

// Refactor with composable

export default {
  name: "OrganizationStructure",
  inheritAttrs: false,

  props: {
    downloading: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    selectAll: { type: Boolean, default: false },
  },

  data: () => ({
    dataDownloaded: false,

    /** @type {number[]} */
    regionIds: [],
    /** @type {number[]} */
    groupIds: [],
    /** @type {number[]} */
    departmentIds: [],

    /** @type {Region[]} */
    regions: [],
    /** @type {Group[]} */
    groups: [],
    /** @type {ApiDepartment[]} */
    departments: [],
  }),

  methods: {
    /** @param groupId {Group["id"]} */
    clearDepartments (groupId) {
      const departmentsIdsToDelete = this.departments.filter(d => d.group.id === groupId).map(d => d.id);
      this.departmentIds = this.departmentIds.filter(dId => !departmentsIdsToDelete.includes(dId));
    },
    /** @param regionId {Region["id"]} */
    clearGroups (regionId) {
      const groupIdsToDelete = this.groups.filter(g => g.region.id === regionId).map(g => g.id);
      this.groupIds = this.groupIds.filter(gId => !groupIdsToDelete.includes(gId));
      groupIdsToDelete.forEach(this.clearDepartments);
    },

    /** @param id {Region["id"]} */
    toggleRegion (id) {
      const pushed = this.toggleItem("region", id);
      if (!pushed) this.clearGroups(id);
      this.emitChanges();
    },
    /** @param id {Group["id"]} */
    toggleGroup (id) {
      const pushed = this.toggleItem("group", id);
      if (!pushed) this.clearDepartments(id);
      this.emitChanges();
    },
    /** @param id {ApiDepartment["id"]} */
    toggleDepartment (id) {
      this.toggleItem("department", id);
      this.emitChanges();
    },

    /**
     * Toggle one of Region | Group | ApiDepartment id
     * @param itemName {"department" | "group" | "region"}
     * @param id {number}
     */
    toggleItem (itemName, id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const list = this[`${itemName}Ids`];
      const index = list.indexOf(id);
      const exist = index !== -1;

      if (exist) list.splice(index, 1);
      else list.push(id);

      return !exist;
    },

    emitChanges () {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("update", {
        activeRegionIds: this.regionIds,
        activeGroupIds: this.groupIds,
        activeDepartmentIds: this.departmentIds,
        regions: this.regions,
        groups: this.groups,
        departments: this.departments,
      });
    },
  },

  async mounted () {
    const [regions, groups, departments] = await getData();

    this.regions = regions;
    this.groups = groups;
    this.departments = departments;

    if (this.selectAll) {
      this.regionIds = regions.map(r => r.id);
      this.groupIds = groups.map(g => g.id);
      this.departmentIds = departments.map(d => d.id);
    }

    this.dataDownloaded = true;
  },
};
</script>

<style lang="scss">
.organization-structure {
  .organization-structure--item {
    margin: 2px 0;

    > span {
      padding: 10px;
      background: rgba(210, 210, 210, 0.35);
      display: flex;
      cursor: pointer;

      &.active {
        background: rgba(2, 116, 217, 0.35);
      }
    }

    &.region {
      font-size: 16px;
    }

    &.group {
      font-size: 14px;
    }

    &.department {
      font-size: 13px;

      .spinner {
        margin-left: 15px;
      }
    }
  }
}
</style>
