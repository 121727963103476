import { defineComponent } from "@vue/composition-api";
import { isString } from "@/utils/check-types-runtime";
export default defineComponent({
    name: "EventFieldName",
    props: {
        name: { type: String, required: true },
    },
    emits: {
        update: (name) => isString(name),
    },
});
