import { computed, defineComponent, onBeforeMount, ref } from "@vue/composition-api";
import InvolvedResources from "@events/components/InvolvedResources.vue";
import { fetchAndTransformRescuers, fetchAndTransformVehicles } from "@/services/event/fetchAndTransformResource";
import { capitalizeWord } from "@/utils/string";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldInvolvedResource",
    components: {
        InvolvedResources,
    },
    props: {
        resourceType: { type: String, required: true },
        involvedResourceIds: { type: Array, required: true },
        readonly: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const resources = ref([]);
        const involvedResources = computed(() => resources.value.filter(r => props.involvedResourceIds.includes(r.id)));
        const resourceTypeText = computed(() => ({ vehicle: "транспорт", rescuer: "персонал" }[props.resourceType]));
        const resourceNotUsedText = computed(() => `${capitalizeWord(resourceTypeText.value)} не вибрано`);
        const setResources = (newResources) => {
            resources.value = newResources;
        };
        const emitInvolvedResources = (newResources) => {
            emit("update", newResources.map(r => r.id));
        };
        onBeforeMount(async () => {
            if (props.resourceType === "rescuer") {
                const fetchedResources = await fetchAndTransformRescuers();
                setResources(fetchedResources);
            }
            if (props.resourceType === "vehicle") {
                const fetchedResources = await fetchAndTransformVehicles();
                setResources(fetchedResources);
            }
        });
        return {
            involvedResources,
            resources,
            resourceTypeText,
            resourceNotUsedText,
            setResources,
            emitInvolvedResources,
        };
    },
});
