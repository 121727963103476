import { EventStage } from "@/models/EventStage";
import { fail, success } from "@/utils/either";
import { updateEventStage as apiUpdateEventStage } from "./api";
export async function updateAndTransformEventStage(eventId, eventStageId, data) {
    const result = await apiUpdateEventStage(eventId, eventStageId, data);
    if (result.isOk()) {
        return success(new EventStage(result.value));
    }
    else {
        return fail(result.value);
    }
}
