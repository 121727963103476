import { fail, success } from "@/utils/either";
import { ValidationError } from "@/models/ApiError";
import { User } from "@/models/User";
import { createUser as apiCreateUser } from "@/api/user";
export async function createUser(userData) {
    const preparedUserDataForApi = {
        last_name: userData.lastName,
        first_name: userData.firstName,
        middle_name: userData.middleName,
        phone: userData.phone,
        address: userData.address,
        additional_info: userData.additionalInfo,
    };
    const result = await apiCreateUser(preparedUserDataForApi);
    if (result.isOk()) {
        return success(new User(result.value));
    }
    else {
        if (result.value instanceof ValidationError) {
            const transformKeys = {
                last_name: "lastName",
                first_name: "firstName",
                middle_name: "middleName",
                phone: "phone",
                address: "address",
                additional_info: "additionalInfo",
            };
            const errors = await result.value.getErrors();
            const preparedUserValidationErrorsForApp = errors.map(e => {
                const field = transformKeys[e.field];
                return {
                    field,
                    messages: e.messages,
                };
            });
            return fail(preparedUserValidationErrorsForApp);
        }
        else {
            return fail(result.value);
        }
    }
}
