import { computed, defineComponent } from "@vue/composition-api";
import EventFieldName from "@events/components/EventFields/EventName.vue";
import EventFieldType from "@events/components/EventFields/EventType.vue";
import EventFieldZone from "@events/components/EventFields/EventZone.vue";
import EventFieldCoordinate from "@events/components/EventFields/EventCoordinate.vue";
import EventFieldCreationTime from "@events/components/EventFields/EventCreationTime.vue";
import EventFieldActivationTime from "@events/components/EventFields/EventActivationTime.vue";
import EventFieldCreator from "@events/components/EventFields/EventCreator.vue";
import EventFieldOwners from "@events/components/EventFields/EventOwners.vue";
import EventFieldLeaders from "@events/components/EventFields/EventLeaders.vue";
import EventFieldVictims from "@events/components/EventFields/EventVictims.vue";
import EventFieldDescription from "@events/components/EventFields/EventDescription.vue";
import EventFieldSequence from "@events/components/EventFields/EventSequence.vue";
import InvolvedResource from "./InvolvedResource.vue";
import CreateEventField from "./Field.vue";
import { useEventData } from "./edit-event-field";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EditableEvent",
    components: {
        CreateEventField,
        EventFieldName,
        EventFieldType,
        EventFieldZone,
        EventFieldCoordinate,
        EventFieldCreationTime,
        EventFieldActivationTime,
        EventFieldOwners,
        EventFieldCreator,
        EventFieldVictims,
        EventFieldDescription,
        InvolvedResource,
        EventFieldSequence,
    },
    props: {
        event: {
            type: null,
            default: () => null,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        visibleFields: {
            type: Array,
            default: () => [],
        },
        requiredFields: {
            type: Array,
            default: () => [],
        },
        readonlyFields: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        "update-event-data": (data) => !!data,
        "update-event-field": ({ field, value }) => !!field && value !== undefined,
        "update-custom-field": ({ field, value }) => !!field && value !== undefined,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { eventData, eventUsers, setFieldValue } = useEventData(props.event);
        emit("update-event-data", eventData);
        const allFields = computed(() => {
            var _a, _b;
            return [
                {
                    name: "type",
                    title: "Тип події",
                    component: EventFieldType,
                    props: {
                        type: eventData.type,
                    },
                    listeners: {
                        "update-type": value => setEventFieldValue("type", value),
                        "update-code": value => emitCustomFieldValue("EVENT_TYPE_CODE", value),
                    },
                },
                {
                    name: "name",
                    title: "Назва/місце події",
                    component: EventFieldName,
                    props: {
                        name: eventData.name,
                    },
                    listeners: {
                        update: value => setEventFieldValue("name", value),
                    },
                },
                {
                    name: "zone",
                    title: "Зона",
                    component: EventFieldZone,
                    props: {
                        zoneId: eventData.zone,
                    },
                    listeners: {
                        update: value => setEventFieldValue("zone", value),
                    },
                },
                {
                    name: "coordinate",
                    title: "Координати місця події",
                    component: EventFieldCoordinate,
                    props: {
                        latitude: eventData.coordinate.latitude,
                        longitude: eventData.coordinate.longitude,
                    },
                    listeners: {
                        "update-latitude": value => setEventFieldValue("coordinateLatitude", value),
                        "update-longitude": value => setEventFieldValue("coordinateLongitude", value),
                    },
                },
                {
                    name: "creationTime",
                    title: "Час отримання повідомлення",
                    component: EventFieldCreationTime,
                    props: {
                        time: eventData.creationTime,
                    },
                    listeners: {
                        update: value => setEventFieldValue("creationTime", value),
                    },
                },
                {
                    name: "activationTime",
                    title: "Час старту події",
                    component: EventFieldActivationTime,
                    props: {
                        time: eventData.activationTime,
                    },
                    listeners: {
                        update: value => setEventFieldValue("activationTime", value),
                    },
                },
                {
                    name: "owners",
                    title: "Дані відповідальних",
                    component: EventFieldOwners,
                    props: {
                        owners: eventUsers.value.owners,
                    },
                    listeners: {
                        update: value => setEventFieldValue("owners", value),
                    },
                },
                {
                    name: "leaders",
                    title: "Дані керівників",
                    component: EventFieldLeaders,
                    props: {
                        leaders: eventUsers.value.leaders,
                    },
                    listeners: {
                        update: value => setEventFieldValue("leaders", value),
                    },
                },
                {
                    name: "creator",
                    title: "Дані заявника",
                    component: EventFieldCreator,
                    props: {
                        creator: eventUsers.value.creator,
                    },
                    listeners: {
                        update: value => setEventFieldValue("creator", value),
                    },
                },
                {
                    name: "victims",
                    title: "Дані постраждалого / постраждалих",
                    component: EventFieldVictims,
                    props: {
                        victims: eventUsers.value.victims,
                    },
                    listeners: {
                        update: value => setEventFieldValue("victims", value),
                    },
                },
                {
                    name: "description",
                    title: "Короткий опис подій",
                    component: EventFieldDescription,
                    props: {
                        description: eventData.description,
                    },
                    listeners: {
                        update: value => setEventFieldValue("description", value),
                    },
                },
                {
                    name: "involvedRescuers",
                    title: "Залучені сили",
                    component: InvolvedResource,
                    props: {
                        resourceType: "rescuer",
                        involvedResourceIds: eventData.involvedRescuers,
                    },
                    listeners: {
                        update: value => setEventFieldValue("involvedRescuers", value),
                    },
                },
                {
                    name: "involvedVehicles",
                    title: "Залучені засоби",
                    component: InvolvedResource,
                    props: {
                        resourceType: "vehicle",
                        involvedResourceIds: eventData.involvedVehicles,
                    },
                    listeners: {
                        update: value => setEventFieldValue("involvedVehicles", value),
                    },
                },
                {
                    name: "sequence",
                    title: "Хід подій",
                    component: EventFieldSequence,
                    props: {
                        sequence: eventData.sequence,
                        eventId: (_b = (_a = props.event) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                    },
                    listeners: {
                        update: value => setEventFieldValue("sequence", value),
                    },
                },
            ];
        });
        const filteredFields = computed(() => props.visibleFields.map(f => {
            const field = allFields.value.find(af => af.name === f);
            if (field === undefined)
                throw new Error(`FIELD "${f}" NOT FOUND`);
            return field;
        }));
        const fieldsWithModProps = computed(() => filteredFields.value.map(field => ({
            ...field,
            props: {
                ...field.props,
                readonly: props.readonly || props.readonlyFields.includes(field.name),
                required: props.requiredFields.includes(field.name),
            },
        })));
        const setEventFieldValue = (field, value) => {
            setFieldValue(field, value);
            emit("update-event-field", { field, value });
            emit("update-event-data", eventData);
        };
        const emitCustomFieldValue = (field, value) => {
            emit("update-custom-field", { field, value });
        };
        return {
            fields: fieldsWithModProps,
            setEventFieldValue,
            emitCustomFieldValue,
        };
    },
});
