import { EventStage } from "@/models/EventStage";
import { fail, success } from "@/utils/either";
import { createEventStage } from "./api";
export async function createAndTransformEventStage(eventId, data) {
    const result = await createEventStage(eventId, data);
    if (result.isOk()) {
        return success(new EventStage(result.value));
    }
    else {
        return fail(result.value);
    }
}
