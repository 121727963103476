import { ApiServerError, ApiUnknownError, request } from "@/services/api";
import { fail } from "@/utils/either";
import { mapResponse2 as mapResponse } from "@/utils/map-checkable-response";
export async function deleteEventStage(eventId, eventStageId) {
    try {
        const response = await request.checkableDelete(`/events/${eventId}/timeline/${eventStageId}`);
        return mapResponse(response, {
            500: ApiServerError,
        });
    }
    catch (e) {
        return fail(new ApiUnknownError(e));
    }
}
