import { ref } from "@vue/composition-api";
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export function useValidationErrors() {
    const errors = ref({});
    const setErrors = (err) => {
        errors.value = err;
    };
    const clearErrors = (fields) => {
        if (fields === undefined) {
            errors.value = {};
            return;
        }
        fields.forEach(field => {
            errors.value[field] = [];
        });
    };
    return {
        errors,
        setErrors,
        clearErrors,
    };
}
