import { reactive, ref } from "@vue/composition-api";
import { isNumber } from "@/utils/check-types-runtime";
import { isEventResponsibility, isUserArray, isUserValue } from "./helpers";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function useEventData(event) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const eventData = reactive({
        name: (_a = event === null || event === void 0 ? void 0 : event.name) !== null && _a !== void 0 ? _a : "",
        type: (_b = event === null || event === void 0 ? void 0 : event.type.id) !== null && _b !== void 0 ? _b : 0,
        zone: (_c = event === null || event === void 0 ? void 0 : event.zone.id) !== null && _c !== void 0 ? _c : 0,
        creator: (_e = (_d = event === null || event === void 0 ? void 0 : event.creator) === null || _d === void 0 ? void 0 : _d.id) !== null && _e !== void 0 ? _e : null,
        owners: (_f = event === null || event === void 0 ? void 0 : event.owners.map(o => ({ ...o, user: o.user.id }))) !== null && _f !== void 0 ? _f : [],
        leaders: (_g = event === null || event === void 0 ? void 0 : event.leaders.map(l => ({ ...l, user: l.user.id }))) !== null && _g !== void 0 ? _g : [],
        victims: (_h = event === null || event === void 0 ? void 0 : event.victims.map(v => v.id)) !== null && _h !== void 0 ? _h : [],
        coordinate: (_j = event === null || event === void 0 ? void 0 : event.coordinate) !== null && _j !== void 0 ? _j : { latitude: 0, longitude: 0 },
        involvedRescuers: (_k = event === null || event === void 0 ? void 0 : event.involvedRescuers.map(r => r.id)) !== null && _k !== void 0 ? _k : [],
        involvedVehicles: (_l = event === null || event === void 0 ? void 0 : event.involvedVehicles.map(v => v.id)) !== null && _l !== void 0 ? _l : [],
        creationTime: (_m = event === null || event === void 0 ? void 0 : event.creationTime) !== null && _m !== void 0 ? _m : new Date(),
        activationTime: (_o = event === null || event === void 0 ? void 0 : event.activationTime) !== null && _o !== void 0 ? _o : null,
        sequence: (_p = event === null || event === void 0 ? void 0 : event.sequence) !== null && _p !== void 0 ? _p : [],
        description: (_q = event === null || event === void 0 ? void 0 : event.description) !== null && _q !== void 0 ? _q : "",
    });
    const eventUsers = ref({
        creator: (_r = event === null || event === void 0 ? void 0 : event.creator) !== null && _r !== void 0 ? _r : null,
        owners: (_s = event === null || event === void 0 ? void 0 : event.owners) !== null && _s !== void 0 ? _s : [],
        leaders: (_t = event === null || event === void 0 ? void 0 : event.leaders) !== null && _t !== void 0 ? _t : [],
        victims: (_u = event === null || event === void 0 ? void 0 : event.victims) !== null && _u !== void 0 ? _u : [],
    });
    const setFieldValue = (key, value) => {
        if (key === "creator") {
            if (isUserValue(value)) {
                eventUsers.value[key] = value;
                eventData[key] = value === null ? null : value.id;
            }
        }
        else if (key === "coordinateLatitude") {
            if (isNumber(value))
                eventData.coordinate.latitude = value;
        }
        else if (key === "coordinateLongitude") {
            if (isNumber(value))
                eventData.coordinate.longitude = value;
        }
        else if (key === "victims") {
            if (isUserArray(value)) {
                eventUsers.value[key] = value;
                eventData[key] = value.map(u => u.id);
            }
        }
        else if (key === "owners" || key === "leaders") {
            if (isEventResponsibility(value)) {
                eventUsers.value[key] = value;
                eventData[key] = value.map(o => ({ ...o, user: o.user.id }));
            }
        }
        else {
            eventData[key] = value;
        }
    };
    return {
        eventData,
        eventUsers,
        setFieldValue,
    };
}
