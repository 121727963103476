import { computed, defineComponent, toRefs } from "@vue/composition-api";
import SearchUser from "@/components/common/SearchUser.vue";
import { User } from "@/models/User";
import SectionTemplate from "./Template.vue";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "FindOrCreateUserPartFind",
    components: {
        SectionTemplate,
        SearchUser,
    },
    props: {
        users: { type: Array, required: true },
        disabled: { type: Boolean, required: true },
        required: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true },
    },
    emits: {
        add: (user) => !!user,
        delete: (userId) => !!userId,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { users } = toRefs(props);
        const userIds = computed(() => users.value.map(u => u.id));
        const empty = computed(() => {
            return props.required && props.users.length === 0;
        });
        const addUser = (user) => {
            emit("add", new User(user));
        };
        const deleteUser = (userId) => {
            emit("delete", userId);
        };
        return {
            userIds,
            empty,
            addUser,
            deleteUser,
        };
    },
});
