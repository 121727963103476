import { computed, defineComponent, toRefs } from "@vue/composition-api";
import SectionCreate from "./Create/index.vue";
import SectionFind from "./Find.vue";
import { FieldName } from "./Create/fields";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "FindOrCreateUser",
    components: {
        SectionCreate,
        SectionFind,
    },
    props: {
        users: { type: Array, required: true },
        hideFind: { type: Boolean, default: false },
        hideCreate: { type: Boolean, default: false },
        showCreateUserFields: { type: Array, default: () => [] },
        showCreateUserValidationErrors: { type: Boolean, default: true },
        multiple: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    emits: {
        "change-users": (users) => !!users,
        "emit-validation-errors": (errors) => !!errors,
        "clear-validation-errors": () => true,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { users } = toRefs(props);
        const disableEditing = computed(() => (props.disabled ||
            (props.multiple ? false : users.value.length >= 1)));
        const addUser = (user) => {
            const newUsers = users.value.concat(user);
            emitUsers(newUsers);
        };
        const deleteUser = (userId) => {
            const newUsers = users.value.filter(u => u.id !== userId);
            emitUsers(newUsers);
        };
        const emitUsers = (newUsers) => {
            emit("change-users", newUsers);
        };
        return {
            disableEditing,
            addUser,
            deleteUser,
        };
    },
});
