import { defineComponent, onBeforeMount, ref } from "@vue/composition-api";
import { fetchAndTransformEventTypes } from "@/services/event-type/fetchAndTransformTypes";
import { isNumber } from "@/utils/check-types-runtime";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "ChooseEventType",
    props: {
        type: { type: Number, required: true },
    },
    emits: {
        "update-type": (typeId) => isNumber(typeId),
        "update-code": (typeCode) => isNumber(typeCode),
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const selectedTypeId = ref(props.type);
        const types = ref([]);
        const selectType = (type) => {
            selectedTypeId.value = type.id;
            emit("update-type", selectedTypeId.value);
            emit("update-code", type.code);
        };
        onBeforeMount(async () => {
            types.value = await fetchAndTransformEventTypes.run();
        });
        return {
            typesDownloading: fetchAndTransformEventTypes.active,
            types,
            selectedTypeId,
            selectType,
        };
    },
});
