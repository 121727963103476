import { computed, defineComponent } from "@vue/composition-api";
import ValidationErrorList from "@/components/common/ValidationErrorList.vue";
import { useValidationErrors } from "@/composable/validation-errors";
import FindOrCreateUsers from "@events/components/FindOrCreateUsers/index.vue";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldCreator",
    components: {
        FindOrCreateUsers,
        ValidationErrorList,
    },
    props: {
        creator: {
            type: null,
            required: true,
        },
    },
    emits: {
        update: (user) => user !== undefined,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { errors, setErrors, clearErrors } = useValidationErrors();
        const users = computed(() => props.creator === null ? [] : [props.creator]);
        const createUserFields = ["lastName", "firstName", "middleName", "phone", "address"];
        const emitCreator = (newUsers) => {
            var _a;
            emit("update", (_a = newUsers[0]) !== null && _a !== void 0 ? _a : null);
        };
        return {
            users,
            createUserFields,
            emitCreator,
            errors,
            setErrors,
            clearErrors,
        };
    },
});
