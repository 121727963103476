import { computed, defineComponent, onBeforeMount, ref } from "@vue/composition-api";
import { fetchAndTransformEventZones } from "@/services/zone/fetchAndTransformZones";
import { isNumber } from "@/utils/check-types-runtime";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldZone",
    props: {
        zoneId: { type: Number, required: true },
    },
    emits: {
        update: (zoneId) => isNumber(zoneId),
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const selectedZoneId = ref(props.zoneId);
        const zones = ref([]);
        const zoneOptions = computed(() => {
            return [
                { title: "Виберіть одну із зон", value: 0 },
                ...zones.value.map(zone => ({
                    title: zone.name,
                    value: zone.id,
                })),
            ];
        });
        const selectZone = (zoneId) => {
            selectedZoneId.value = zoneId;
            emit("update", selectedZoneId.value);
        };
        onBeforeMount(async () => {
            zones.value = await fetchAndTransformEventZones.run();
        });
        return {
            zonesDownloading: fetchAndTransformEventZones.active,
            selectedZoneId,
            zoneOptions,
            selectZone,
        };
    },
});
