import { fetchRescuers2 as fetchRescuers } from "@/api/office";
import { fetchVehicles2 as fetchVehicles } from "@/api/vehicle";
import { User } from "@/models/User";
import { Department } from "@/models/Department";
import { Vehicle } from "@/models/Vehicle";
export async function fetchAndTransformRescuers() {
    const rescuers = await fetchRescuers();
    const transformedRescuers = rescuers.map(r => new User(r)).filter(r => r.post !== null);
    return transformedRescuers.map(rescuer => {
        var _a, _b, _c;
        return ({
            id: rescuer.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            department: new Department(rescuer.post.location),
            title: rescuer.fullName,
            callSign: (_c = (_b = (_a = rescuer.post) === null || _a === void 0 ? void 0 : _a.appliance) === null || _b === void 0 ? void 0 : _b.call_sign) !== null && _c !== void 0 ? _c : "",
        });
    });
}
export async function fetchAndTransformVehicles() {
    const vehicles = await fetchVehicles();
    const transformedVehicles = vehicles.map(v => new Vehicle(v));
    return transformedVehicles.map(vehicle => ({
        id: vehicle.id,
        department: vehicle.ownership.department,
        title: vehicle.brand,
        callSign: vehicle.callSign,
    }));
}
