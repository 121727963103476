var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"organization-structure"},[(!_vm.dataDownloaded)?_c('AppLoader',{attrs:{"icon-left":"","icon-scale":"1.2"}},[_vm._v(" Завантаження даних ")]):_c('AppSelectableList',{attrs:{"list":_vm.regions,"key-field":"id","selected-item-values":_vm.regionIds},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var region = ref.item;
var selectedRegion = ref.selected;
return [_c('div',{staticClass:"organization-structure--item region"},[(_vm.selectable)?_c('span',{class:{active: selectedRegion},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.toggleRegion(region.id)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleRegion(region.id)}}},[_vm._v(" "+_vm._s(region.title)+" ")]):_vm._e(),_c('AppSelectableList',{attrs:{"list":_vm.groups.filter(function (g) { return g.region.id === region.id && _vm.regionIds.includes(g.region.id); }),"key-field":"id","selected-item-values":_vm.groupIds},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var group = ref.item;
var selectedGroup = ref.selected;
return [_c('div',{staticClass:"organization-structure--item group"},[(_vm.selectable)?_c('span',{class:{active: selectedGroup},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.toggleGroup(group.id)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleGroup(group.id)}}},[_vm._v(" - "+_vm._s(group.title)+" ")]):_vm._e(),_c('AppSelectableList',{attrs:{"list":_vm.departments.filter(function (d){ return (d.group.id === group.id && _vm.groupIds.includes(d.group.id)); }),"selected-item-values":_vm.departmentIds,"key-field":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var department = ref.item;
var selectedDepartment = ref.selected;
return [_c('div',{staticClass:"organization-structure--item department"},[(_vm.selectable)?_c('span',{class:{active: selectedDepartment},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.toggleDepartment(department.id)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleDepartment(department.id)}}},[_vm._v(" --- "+_vm._s(department.title)+" "),(_vm.downloading)?_c('AppLoader',{staticClass:"spinner",attrs:{"icon-scale":"1.3"}}):_vm._e()],1):_vm._e(),_vm._t("default",null,{"region":region,"group":group,"department":department,"regionIds":_vm.regionIds,"groupIds":_vm.groupIds,"departmentIds":_vm.departmentIds,"isRegionSelected":selectedRegion,"isGroupSelected":selectedGroup,"isDepartmentSelected":selectedDepartment})],2)]}}],null,true)})],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }