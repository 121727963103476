import { User } from "@/models/User";
export const isUserArray = (value) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return Array.isArray(value) && value.every(u => u instanceof User);
};
export const isUserValue = (value) => {
    return (value instanceof User) || value === null;
};
export const isEventResponsibility = (value) => {
    return (Array.isArray(value) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        value.every(r => ("time" in r && r.time instanceof Date &&
            "user" in r && r.user instanceof User)));
};
