import { defineComponent, toRefs, watch, computed } from "@vue/composition-api";
import ValidationErrorList from "@/components/common/ValidationErrorList.vue";
import { createUser as serviceCreateUser } from "@/services/user/createFromEvent";
import { ServerError } from "@/models/ApiError";
import { useValidationErrors } from "@/composable/validation-errors";
import SectionTemplate from "../Template.vue";
import { useCreateUserFields } from "./fields";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "FindOrCreateUserPartCreate",
    components: {
        SectionTemplate,
        ValidationErrorList,
    },
    props: {
        showFields: { type: Array, required: true },
        showErrors: { type: Boolean, required: true },
        disabled: { type: Boolean, required: true },
    },
    emits: {
        "create-user": (user) => !!user,
        "emit-validation-errors": (errors) => !!errors,
        "clear-validation-errors": (fields) => true || fields,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { showFields } = toRefs(props);
        const { errors, setErrors, clearErrors } = useValidationErrors();
        const { fields, fieldsFulfilled, fieldsToData, updateField, clearFields } = useCreateUserFields({ showFields, clearErrors });
        const nameFieldList = ["lastName", "firstName", "middleName"];
        const nameFields = computed(() => fields.value.filter(f => nameFieldList.includes(f.name)));
        const otherFields = computed(() => fields.value.filter(f => !nameFieldList.includes(f.name)));
        const customUpdateField = (name, value) => {
            updateField(name, value);
            emit("clear-validation-errors", [name]);
        };
        const createUser = async () => {
            const result = await serviceCreateUser(fieldsToData.value);
            if (result.isOk()) {
                emit("create-user", result.value);
                clearFields();
                emit("clear-validation-errors", undefined);
            }
            else {
                if (result.value instanceof ServerError) {
                    await result.value.print();
                }
                else {
                    setErrors(result.value);
                    emit("emit-validation-errors", result.value);
                }
            }
        };
        watch(() => props.disabled, () => {
            clearFields();
            emit("clear-validation-errors", undefined);
        });
        return {
            nameFieldList,
            nameFields,
            otherFields,
            fieldsFulfilled,
            customUpdateField,
            clearFields,
            errors,
            createUser,
        };
    },
});
