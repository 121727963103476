import { defineComponent } from "@vue/composition-api";
import EventResponsibilities from "@events/components/EventResponsibilities.vue";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldLeaders",
    components: {
        EventResponsibilities,
    },
    props: {
        leaders: {
            type: Array,
            required: true,
        },
    },
    emits: {
        update: (leaders) => leaders !== undefined,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const emitLeaders = (newLeaders) => {
            emit("update", newLeaders);
        };
        return {
            emitLeaders,
        };
    },
});
