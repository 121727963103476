import { computed, defineComponent, ref } from "@vue/composition-api";
import OrganizationStructure from "@/components/common/OrganizationStructure.vue";
import { searchInListByQuery, toggleItem } from "@/utils/list";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "InvolvedResources",
    components: {
        OrganizationStructure,
    },
    props: {
        titleInvolved: { type: String, required: true },
        titleInvolve: { type: String, required: true },
        titleEmpty: { type: String, required: true },
        involvedResources: { type: Array, required: true },
        allResources: { type: Array, required: true },
        readonly: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
    },
    emits: {
        "change-involved-resources": (resources) => !!resources,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const involvedResourceIds = computed(() => {
            return props.involvedResources.map(r => r.id);
        });
        const empty = computed(() => props.required && props.involvedResources.length === 0);
        const filterQuery = ref("");
        const allResourcesFilteredByQuery = computed(() => {
            return searchInListByQuery(props.allResources, filterQuery.value);
        });
        const sortedInvolvedResourcesByDepartmentId = computed(() => {
            const initial = {};
            return props.involvedResources.reduce((accumulator, resource) => {
                const dId = resource.department.id;
                if (dId in accumulator)
                    accumulator[dId].push(resource);
                else
                    accumulator[dId] = [resource];
                return accumulator;
            }, initial);
        });
        const involveResource = (resource) => {
            const newInvolvedResources = toggleItem(props.involvedResources, resource, "id");
            emit("change-involved-resources", newInvolvedResources);
        };
        return {
            empty,
            filterQuery,
            allResourcesFilteredByQuery,
            sortedInvolvedResourcesByDepartmentId,
            involvedResourceIds,
            involveResource,
        };
    },
});
