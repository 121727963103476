import { computed, defineComponent } from "@vue/composition-api";
const DEFAULT_FIELD = "__none-field__";
export default defineComponent({
    name: "ValidationErrorList",
    props: {
        errors: { type: Object, default: () => [] },
        showField: { type: String, default: DEFAULT_FIELD },
        showFields: { type: Array, default: () => [DEFAULT_FIELD] },
        hideField: { type: String, default: DEFAULT_FIELD },
        hideFields: { type: Array, default: () => [DEFAULT_FIELD] },
        messageClass: { type: String, default: "" },
        errorClass: { type: String, default: "" },
    },
    setup(props) {
        const errorsArray = computed(() => {
            return Object.entries(props.errors).map(([field, messages]) => ({ field, messages }));
        });
        const visibleErrors = computed(() => {
            if (props.showFields.length > 0 && props.showFields[0] !== DEFAULT_FIELD) {
                return errorsArray.value.filter(e => props.showFields.includes(e.field));
            }
            else if (props.showField !== DEFAULT_FIELD) {
                return errorsArray.value.filter(e => e.field === props.showField);
            }
            else {
                return errorsArray.value;
            }
        });
        const filteredErrors = computed(() => {
            if (props.hideFields.length > 0 && props.hideFields[0] !== DEFAULT_FIELD) {
                return visibleErrors.value.filter(e => !props.hideFields.includes(e.field));
            }
            else if (props.hideField !== DEFAULT_FIELD) {
                return visibleErrors.value.filter(e => e.field !== props.hideField);
            }
            else {
                return visibleErrors.value;
            }
        });
        return {
            filteredErrors,
        };
    },
});
