import { defineComponent } from "@vue/composition-api";
import { searchUsersByQuery } from "../../api/user";
export default defineComponent({
    name: "SearchUser",
    props: {
        disableSearch: {
            type: Boolean,
            default: false,
        },
        userIdsToExclude: {
            type: Array,
            default: () => [],
        },
    },
    emits: {
        search: (user) => !!user,
    },
    setup(props, { emit }) {
        const searchUsers = async (input) => {
            if (input.length < 2)
                return [];
            try {
                const users = await searchUsersByQuery(input);
                return users.filter(u => !props.userIdsToExclude.includes(u.id));
            }
            catch (e) {
                console.error(e);
                return [];
            }
        };
        const handleSubmit = (user) => {
            if (user !== undefined)
                emit("search", user);
        };
        return {
            searchUsers,
            handleSubmit,
        };
    },
});
