import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import { updateEventStage } from "@/services/event-sequence/update-event-stage";
import { deleteEventStage } from "@/services/event-sequence/delete-event-stage";
import { createEventStage } from "@/services/event-sequence/create-event-stage";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldSequence",
    props: {
        eventId: { type: Number, required: true },
        sequence: { type: Array, required: true },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    emits: {
        update: (sequence) => !!sequence,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const sequence = ref(props.sequence);
        const updateStage = async (id, index, field, value) => {
            sequence.value[index][field] = value;
            if (field === "description" && value === "")
                return;
            const result = await updateEventStage.run(props.eventId, id, sequence.value[index]);
            if (result.isOk()) {
                sequence.value[index] = result.value;
                emitSequenceData();
            }
        };
        const removeStage = async (id, index) => {
            const result = await deleteEventStage.run(props.eventId, id);
            if (result.isOk()) {
                sequence.value.splice(index, 1);
                emitSequenceData();
            }
        };
        const newStage = reactive({ time: new Date(), description: "" });
        const newStageFulfilled = computed(() => newStage.description !== "");
        const updateNewStage = (field, value) => {
            newStage[field] = value;
        };
        const addStage = async () => {
            const result = await createEventStage.run(props.eventId, newStage);
            if (result.isOk()) {
                sequence.value.push(result.value);
                emitSequenceData();
                newStage.time = new Date();
                newStage.description = "";
            }
        };
        const emitSequenceData = () => {
            emit("update", sequence.value);
        };
        return {
            stageUpdating: updateEventStage.active,
            updateStage,
            stageRemoving: deleteEventStage.active,
            removeStage,
            newStage,
            newStageFulfilled,
            updateNewStage,
            stageCreating: createEventStage.active,
            addStage,
        };
    },
});
