import { fail, success } from "./either";
import { ApiUnknownError } from "@/services/api";
export function mapResponse(result, codes) {
    if (result.isFail()) {
        const codeEntry = Object.entries(codes).find(([code]) => (code === result.value.response.status.toString()));
        if (codeEntry === undefined)
            return fail(result.value);
        const ErrorClass = codeEntry[1];
        return fail(new ErrorClass(result.value.response));
    }
    else {
        return success(result.value);
    }
}
export function mapResponse2(result, codes) {
    if (result.isFail()) {
        const codeEntry = Object.entries(codes).find(([code]) => (code === result.value.response.status.toString()));
        if (codeEntry === undefined)
            return fail(new ApiUnknownError(result.value));
        const ErrorClass = codeEntry[1];
        return fail(new ErrorClass(result.value.response));
    }
    else {
        return success(result.value);
    }
}
