import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { fetchAndTransformEventDescriptions } from "@/services/event-description/fetchAndTransformDescriptions";
import { isString } from "@/utils/check-types-runtime";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldDescription",
    props: {
        description: { type: String, required: true },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    emits: {
        update: (description) => isString(description),
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const descriptionValue = ref(props.description);
        const descriptions = ref([]);
        const defaultDescriptionOptions = { title: "Виберіть опис події (якщо потрібно)", value: "" };
        const descriptionOptions = computed(() => [
            defaultDescriptionOptions,
            ...descriptions.value.map(d => ({ title: d.name, value: d.description })),
        ]);
        const setDescription = (desc) => {
            descriptionValue.value = desc;
            emit("update", descriptionValue.value);
        };
        onMounted(async () => {
            const ds = await fetchAndTransformEventDescriptions.run();
            descriptions.value = ds;
        });
        return {
            descriptionsDownloading: fetchAndTransformEventDescriptions.active,
            descriptionValue,
            descriptionOptions,
            setDescription,
        };
    },
});
