import { request } from "./request";
import generateParams from "../utils/generate-params";
import { mapResponse } from "../utils/map-checkable-response";
import { ValidationError } from "../models/ApiError";
function fetchUsersFromApi(params) {
    return request.get("/users", { params }).then(res => res.results);
}
export function fetchUsers(role) {
    const params = {};
    if (role !== undefined)
        Object.assign(params, generateParams("role", null, role));
    return fetchUsersFromApi(params);
}
export function searchUsersByQuery(query) {
    const params = generateParams("search", null, query);
    return fetchUsersFromApi(params);
}
export async function createUser(userData) {
    const res = await request.checkablePost("/users", userData);
    return mapResponse(res, {
        400: ValidationError,
    });
}
