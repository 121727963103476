import { defineComponent, ref } from "@vue/composition-api";
import { isNumber } from "@/utils/check-types-runtime";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldCoordinate",
    props: {
        latitude: { type: Number, required: true },
        longitude: { type: Number, required: true },
    },
    emits: {
        "update-latitude": (latitude) => isNumber(latitude),
        "update-longitude": (longitude) => isNumber(longitude),
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const latitudeValue = ref(props.latitude);
        const longitudeValue = ref(props.longitude);
        const setCoordinate = (type, value) => {
            const newValue = parseFloat(value);
            if (type === "coordinateLatitude") {
                latitudeValue.value = newValue;
                emit("update-latitude", latitudeValue.value);
            }
            if (type === "coordinateLongitude") {
                longitudeValue.value = newValue;
                emit("update-longitude", longitudeValue.value);
            }
        };
        return {
            latitudeValue,
            longitudeValue,
            setCoordinate,
        };
    },
});
