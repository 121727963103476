import { request, ApiServerError, ApiUnknownError } from "@/services/api";
import { fail } from "@/utils/either";
import { mapResponse2 as mapResponse } from "@/utils/map-checkable-response";
export async function createEventStage(eventId, data) {
    try {
        const response = await request.checkablePost(`/events/${eventId}/timeline`, data);
        return mapResponse(response, {
            500: ApiServerError,
        });
    }
    catch (e) {
        return fail(new ApiUnknownError(e));
    }
}
