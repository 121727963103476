import { defineComponent } from "@vue/composition-api";
import ValidationErrorList from "@/components/common/ValidationErrorList.vue";
import { useValidationErrors } from "@/composable/validation-errors";
import FindOrCreateUsers from "@events/components/FindOrCreateUsers/index.vue";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "EventFieldVictims",
    components: {
        FindOrCreateUsers,
        ValidationErrorList,
    },
    props: {
        victims: { type: Array, required: true },
    },
    emits: {
        update: (users) => !!users,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const { errors, setErrors, clearErrors } = useValidationErrors();
        const createUserFields = ["lastName", "firstName", "middleName", "phone", "address", "additionalInfo"];
        const emitVictims = (newUsers) => {
            emit("update", newUsers);
        };
        return {
            createUserFields,
            emitVictims,
            errors,
            setErrors,
            clearErrors,
        };
    },
});
