var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"involved-resources",class:{empty: _vm.empty}},[_c('AppCollapse',{attrs:{"title-class":"involved-resources--title","initially-closed":!_vm.readonly},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.titleInvolved)+" ")]},proxy:true},{key:"content",fn:function(){return [(_vm.allResources.length>0 && _vm.involvedResources.length===0)?_c('div',{staticClass:"involved-resources--empty",class:{empty: _vm.empty}},[_vm._v(" "+_vm._s(_vm.titleEmpty)+" ")]):_vm._e(),_c('OrganizationStructure',{staticClass:"involved-resources--organization",attrs:{"selectable":false,"select-all":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var department = ref.department;
var group = ref.group;
var region = ref.region;
return [(_vm.sortedInvolvedResourcesByDepartmentId[department.id])?_c('div',[_c('p',{staticClass:"involved-resources--resource-relations"},[_vm._v(" "+_vm._s(region.title)+" "),_c('mark',[_vm._v(">")]),_vm._v(" "+_vm._s(group.title)+" "),_c('mark',[_vm._v(">")]),_vm._v(" "+_vm._s(department.title)+" ")]),_vm._l((_vm.sortedInvolvedResourcesByDepartmentId[department.id]),function(resource){return _c('div',{key:resource.id,staticClass:"involved-resources--resource readonly",class:{readonly: _vm.readonly}},[_vm._v(" "+_vm._s(resource.title)+" "),(resource.callSign)?_c('b',[_vm._v(_vm._s(resource.callSign))]):_vm._e()])})],2):_vm._e()]}}])})]},proxy:true}])}),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],staticClass:"involved-resources--separator"}),_c('AppCollapse',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],attrs:{"title-class":"involved-resources--title"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.titleInvolve)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"involved-resources--filter"},[_c('AppText',{attrs:{"placeholder":"Фільтрувати","value":_vm.filterQuery},on:{"input":function($event){_vm.filterQuery=$event}}})],1),_c('OrganizationStructure',{staticClass:"involved-resources--organization",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var department = ref.department;
var isDepartmentSelected = ref.isDepartmentSelected;
return [_c('AppSelectableList',{directives:[{name:"show",rawName:"v-show",value:(isDepartmentSelected),expression:"isDepartmentSelected"}],attrs:{"list":_vm.allResourcesFilteredByQuery.filter(function (r) { return r.department.id === department.id; }),"key-field":"id","selected-item-values":_vm.involvedResourceIds},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var resource = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"involved-resources--resource",class:{selected: selected, readonly: _vm.readonly},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.involveResource(resource)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.involveResource(resource)}}},[_vm._v(" "+_vm._s(resource.title)+" "),(resource.callSign)?_c('b',[_vm._v(_vm._s(resource.callSign))]):_vm._e()])]}}],null,true)})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }